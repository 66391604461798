<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Formulaire d'inscription: 👉 </strong>
        <a href="https://forms.gle/n4NkcFz29Y6WWATdA" target="_blank">Google Forms</a><br />
<!--        <a href="#">Google Forms (bientôt)</a><br />-->
        Développé par
        <a href="https://github.com/Bhacaz">Jean-Francis Bastien</a>.<br />
        Pour
        <a href="https://collegesaintsacrement.qc.ca"
        >Collége Saint-Sacrement</a
        >
      </p>
    </div>
  </footer>
</template>
